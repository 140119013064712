import React, { useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { disableControlsByClass } from "./utils"
import isEmpty from "is-empty";

export const TypeBusiness = (props) => {

    const [errorGiro, setErrorGiro] = useState('');
    const errorGiroOtros = '';

    const update = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        if (target.type === 'checkbox') {
            if (validateSelection(e.target.className)) {
                props.update(id, value);
            }
        }
        else {
            props.update(id, value);
        }
    };

    const blur = (e) => {
        if (e.target.type === 'text') {
            if (validateSelection(e.target.className)) {
                props.update(e.target.id, e.target.value);
            }
        }
    };

    const validateSelection = (category) => {
        if (category === "Giro")
            return disableControlsByClass(category, 1);

        return true;
    };

    const validate = () => {

        let valid = true;
        let language = localStorage.getItem("exmi_language");

        // Errors clean up
        setErrorGiro("");

        //// Giro de la empresa
        props.form.giroFabricanteMuebles = !isEmpty(props.form.giroFabricanteMuebles) ? props.form.giroFabricanteMuebles : false;
        props.form.giroTapiceroCarpintero = !isEmpty(props.form.giroTapiceroCarpintero) ? props.form.giroTapiceroCarpintero : false;
        props.form.giroComerciante = !isEmpty(props.form.giroComerciante) ? props.form.giroComerciante : false;
        props.form.giroEquipamientoResidencialCocinasyClosets = !isEmpty(props.form.giroEquipamientoResidencialCocinasyClosets) ? props.form.giroEquipamientoResidencialCocinasyClosets : false;
        props.form.giroMaderero = !isEmpty(props.form.giroMaderero) ? props.form.giroMaderero : false;
        props.form.giroArquitectoConsultor = !isEmpty(props.form.giroArquitectoConsultor) ? props.form.giroArquitectoConsultor : false;
        props.form.giroOtros = !isEmpty(props.form.giroOtros) ? props.form.giroOtros : "";

        // Giro
        if (
            !props.form.giroFabricanteMuebles &&
            !props.form.giroTapiceroCarpintero &&
            !props.form.giroComerciante &&
            !props.form.giroEquipamientoResidencialCocinasyClosets &&
            !props.form.giroMaderero &&
            !props.form.giroArquitectoConsultor &&
            Validator.isEmpty(props.form.giroOtros)) {
            if (language === "es")
                setErrorGiro("Debe seleccionar al menos un Giro de la Empresa");
            else
                setErrorGiro("You must select at least one type of business");

            valid = false;
        }

        if (valid) {
            /*
            console.log(props.form.vip);

            if (!props.form.vip)
                props.submit();
            else
            */
            props.nextStep();
        }
    };

    const previous = () => {
        if (props.form.pais === "MX")
            props.goToStep(1);
        else
            props.goToStep(2);
    }

    return (
        <div>

            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                    <b>{props.locale.dashboard_comprador_label_type_business}</b>
                </h4>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_furniture_manufacturer }</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroFabricanteMuebles" onChange={update} checked={props.form.giroFabricanteMuebles} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_upholster_carpenter}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroTapiceroCarpintero" onChange={update} checked={props.form.giroTapiceroCarpintero} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_seller}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroComerciante" onChange={update} checked={props.form.giroComerciante} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_residential_equipment }</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroEquipamientoResidencialCocinasyClosets" onChange={update} checked={props.form.giroEquipamientoResidencialCocinasyClosets} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_wood_seller  }</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroMaderero" onChange={update} checked={props.form.giroMaderero} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_architect_consultant }</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroArquitectoConsultor" onChange={update} checked={props.form.giroArquitectoConsultor} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s12 l10">
                <input
                    onChange={update}
                    onBlur={blur}
                    value={props.form.giroOtros}
                    error={errorGiroOtros}
                    id="giroOtros"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorGiroOtros
                    }, "Giro")}
                />
                <label htmlFor="giroOtros">{props.locale.dashboard_comprador_label_others}</label>
                <span className="red-text">{errorGiroOtros}</span>
            </div>

            <div className="input-field col s12"><span className="red-text">{errorGiro}</span></div>
            <br></br><br></br><br></br>
            <Stats step={2} {...props} nextStep={validate} previousStep={previous} locale={props.locale} vip={props.form.vip} />
        </div>
    )
};