import React, { useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { disableControlsByClass } from "./utils"
import isEmpty from "is-empty";

export const ProductsInterest = (props) => {

    const [errorProductosInteres, setErrorProductosInteres] = useState('')
    const errorProductosInteresOtros = '';
  
    const update = (e) => {
  
      const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const id = target.id;
  
      if (target.type === 'checkbox') {
        if (validateSelection(e.target.className)) {
          props.update(id, value);
        }
      }
      else {
        props.update(id, value);
      }
    };
  
    const blur = (e) => {
      if (e.target.type === 'text') {
        if (validateSelection(e.target.className)) {
          props.update(e.target.id, e.target.value);
        }
      }
    };
  
    const submit = () => {
  
      let valid = true;
      let language = localStorage.getItem("exmi_language");

      setErrorProductosInteres("");

      /*
      interesesAbrasivos: false,
      interesesAccesorios: false,
      interesesHerrajes: false,
      interesesHerramientasyEquipos: false,
      interesesMaderasyChapas: false,
      interesesMaquinaria: false,
      interesesPegamentosyAdhesivos: false,
      interesesPinturasyRecubrimientos: false,
      interesesPolimeros: false,
      interesesServicios: false,
      interesesSoftware: false,
      interesesTapizados: false,
      interesesNoTejidos: false,

      */
  
      // Intereses

      props.form.interesesAbrasivos = !isEmpty(props.form.interesesAbrasivos) ? props.form.interesesAbrasivos : false;
      props.form.interesesAccesorios = !isEmpty(props.form.interesesAccesorios) ? props.form.interesesAccesorios : false;
      props.form.interesesHerrajes = !isEmpty(props.form.interesesHerrajes) ? props.form.interesesHerrajes : false;
      props.form.interesesHerramientasyEquipos = !isEmpty(props.form.interesesHerramientasyEquipos) ? props.form.interesesHerramientasyEquipos : false;
      props.form.interesesMaderasyChapas = !isEmpty(props.form.interesesMaderasyChapas) ? props.form.interesesMaderasyChapas : false;
      props.form.interesesMaquinaria = !isEmpty(props.form.interesesMaquinaria) ? props.form.interesesMaquinaria : false;
      props.form.interesesPegamentosyAdhesivos = !isEmpty(props.form.interesesPegamentosyAdhesivos) ? props.form.interesesPegamentosyAdhesivos : false;
      props.form.interesesPinturasyRecubrimientos = !isEmpty(props.form.interesesPinturasyRecubrimientos) ? props.form.interesesPinturasyRecubrimientos : false;
      props.form.interesesPolimeros = !isEmpty(props.form.interesesPolimeros) ? props.form.interesesPolimeros : false;
      props.form.interesesServicios = !isEmpty(props.form.interesesServicios) ? props.form.interesesServicios : false;
      props.form.interesesSoftware = !isEmpty(props.form.interesesSoftware) ? props.form.interesesSoftware : false;
      props.form.interesesTapizados = !isEmpty(props.form.interesesTapizados) ? props.form.interesesTapizados : false;
      props.form.interesesNoTejidos = !isEmpty(props.form.interesesNoTejidos) ? props.form.interesesNoTejidos : false;
      props.form.interesesOtrosTexto = !isEmpty(props.form.interesesOtrosTexto) ? props.form.interesesOtrosTexto : "";

    
  
      if (
        !props.form.interesesAbrasivos &&
        !props.form.interesesAccesorios &&
        !props.form.interesesHerrajes &&
        !props.form.interesesHerramientasyEquipos &&
        !props.form.interesesMaderasyChapas &&
        !props.form.interesesMaquinaria &&
        !props.form.interesesPegamentosyAdhesivos &&
        !props.form.interesesPinturasyRecubrimientos &&
        !props.form.interesesPolimeros &&
        !props.form.interesesServicios &&
        !props.form.interesesSoftware &&
        !props.form.interesesTapizados &&
        !props.form.interesesNoTejidos &&
        Validator.isEmpty(props.form.interesesOtros)
      ) {
        if (language === "es") {
          setErrorProductosInteres("Debe seleccionar al menos un producto de interes");
        }
        else {
          setErrorProductosInteres("You must select at least one product of interest");
        }
  
        valid = false;
      }
  
      if (valid)
        props.submit();
  
    };
  
    const validateSelection = (category) => {
      if (category === "ProductosInteres") {
        return disableControlsByClass(category, 5);
      }
      return true;
    };
  
  

    return (
      <div>
        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
          <h4>
            <b>{props.locale.dashboard_comprador_label_interest_title}</b>
          </h4>
        </div>
        <div className="ProductosInteresParent">
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_machinery}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesMaquinaria" onChange={update} checked={props.form.interesesMaquinaria} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_wood_boards}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesMaderasyChapas" onChange={update} checked={props.form.interesesMaderasyChapas} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_paints_coatings}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesPinturasyRecubrimientos" onChange={update} checked={props.form.interesesPinturasyRecubrimientos} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_polymers}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesPolimeros" onChange={update} checked={props.form.interesesPolimeros} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_software}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesSoftware" onChange={update} checked={props.form.interesesSoftware} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_glues_adhesives}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesPegamentosyAdhesivos" onChange={update} checked={props.form.interesesPegamentosyAdhesivos} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_textil}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesTapizados" onChange={update} checked={props.form.interesesTapizados} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_abrasives}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesAbrasivos" onChange={update} checked={props.form.interesesAbrasivos} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_accesories}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesAccesorios" onChange={update} checked={props.form.interesesAccesorios} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_tools_equipment}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesHerramientasyEquipos" onChange={update} checked={props.form.interesesHerramientasyEquipos} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_furniture_fittings}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesHerrajes" onChange={update} checked={props.form.interesesHerrajes} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_services}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesServicios" onChange={update} checked={props.form.interesesServicios} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
  
          <div className="ProductosInteresChild">
            <div className="input-field col s8 l4">{props.locale.dashboard_comprador_label_nonwovens}</div>
            <div className="input-field col s4 l2">
              <div className="switch">
                <label>
                  <input type="checkbox" id="interesesNoTejidos" onChange={update} checked={props.form.interesesNoTejidos} className="ProductosInteres" />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
  
        <div className="input-field col s12">
          <input
            onChange={update}
            onBlur={blur}
            value={props.form.interesesOtros}
            error={errorProductosInteresOtros}
            id="interesesOtros"
            type="text"
             className={classnames("font-face-msb", {
              invalid: errorProductosInteresOtros
            }, "ProductosInteres")}
          />
          <label htmlFor="interesesOtros">{props.locale.dashboard_comprador_label_others}</label>
          <span className="red-text">{errorProductosInteresOtros}</span>
        </div>
        <div className="input-field col s12"><span className="red-text">{errorProductosInteres}</span></div>
  
        <div className="col s12">
          <label>{props.locale.dashboard_comprador_label_advice}</label>
          <a href={props.locale.dashboard_comprador_label_privacy_url} target="_blank" rel="noopener noreferrer">{props.locale.dashboard_comprador_label_privacy}</a>
        </div>
        <Stats step={5} {...props} nextStep={submit}  vip={props.form.vip} />
      </div>
    );
  };