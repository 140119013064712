import React, { useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { disableControlsByClass } from "./utils"
import isEmpty from "is-empty";

export const MainActivityCompany = (props) => {

    const [errorPrincipalActividad, setErrorPrincipalActividad] = useState('')
    const errorPrincipalActividadOtros = '';

    const update = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        if (target.type === 'checkbox') {
            if (validateSelection(e.target.className)) {
                props.update(id, value);
            }
        }
        else {
            props.update(id, value);
        }
    };

    const blur = (e) => {
        if (e.target.type === 'text') {
            if (validateSelection(e.target.className)) {
                props.update(e.target.id, e.target.value);
            }
        }
    };

    const validateSelection = (category) => {
        return disableControlsByClass(category, 3);
    };

    const validate = () => {

        let valid = true;
        let language = localStorage.getItem("exmi_language");

        // Errors clean up
        setErrorPrincipalActividad("");

        //// Principal Actividad
        props.form.principalActividadFabricaMueblesAltoVolumen = !isEmpty(props.form.principalActividadFabricaMueblesAltoVolumen) ? props.form.principalActividadFabricaMueblesAltoVolumen : false;
        props.form.principalActividadFabricaMueblesDisenioProyecto = !isEmpty(props.form.principalActividadFabricaMueblesDisenioProyecto) ? props.form.principalActividadFabricaMueblesDisenioProyecto : false;
        props.form.principalActividadEquipamiento = !isEmpty(props.form.principalActividadEquipamiento) ? props.form.principalActividadEquipamiento : false;
        props.form.principalActividadComercializacion = !isEmpty(props.form.principalActividadComercializacion) ? props.form.principalActividadComercializacion : false;
        props.form.principalActividadAsesoriaDisenioyFabricacion = !isEmpty(props.form.principalActividadAsesoriaDisenioyFabricacion) ? props.form.principalActividadAsesoriaDisenioyFabricacion : false;
        props.form.principalActividadOtros = !isEmpty(props.form.principalActividadOtros) ? props.form.principalActividadOtros : "";

        // Principal Actividad
        if (
            !props.form.principalActividadFabricaMueblesAltoVolumen &&
            !props.form.principalActividadFabricaMueblesDisenioProyecto &&
            !props.form.principalActividadEquipamiento &&
            !props.form.principalActividadComercializacion &&
            !props.form.principalActividadAsesoriaDisenioyFabricacion &&
            !props.form.principalActividadOtros &&
            Validator.isEmpty(props.form.principalActividadOtros)) {
            if (language === "es")
                setErrorPrincipalActividad("Debe seleccionar al menos una Actividad Principal");
            else
                setErrorPrincipalActividad("You must select at least one main business activity");

            valid = false;
        }

        if (valid)
            props.nextStep();

    };

    return (
        <div>

            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                    <b>{props.locale.dashboard_comprador_label_business}</b>
                </h4>
            </div>
            <div className="input-field col s8">{props.locale.dashboard_comprador_label_principalActividadFabricaMueblesAltoVolumen}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="principalActividadFabricaMueblesAltoVolumen" onChange={update} checked={props.form.principalActividadFabricaMueblesAltoVolumen} className="PrincipalActividad" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_principalActividadFabricaMueblesDisenioProyecto}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="principalActividadFabricaMueblesDisenioProyecto" onChange={update} checked={props.form.principalActividadFabricaMueblesDisenioProyecto} className="PrincipalActividad" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_principalActividadEquipamiento}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="principalActividadEquipamiento" onChange={update} checked={props.form.principalActividadEquipamiento} className="PrincipalActividad" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_principalActividadComercializacion}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="principalActividadComercializacion" onChange={update} checked={props.form.principalActividadComercializacion} className="PrincipalActividad" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>


            <div className="input-field col s8">{props.locale.dashboard_comprador_label_principalActividadAsesoriaDisenioyFabricacion}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="principalActividadAsesoriaDisenioyFabricacion" onChange={update} checked={props.form.principalActividadAsesoriaDisenioyFabricacion} className="PrincipalActividad" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s12 l10">
                <input
                    onChange={update}
                    onBlur={blur}
                    value={props.form.principalActividadOtros}
                    error={errorPrincipalActividadOtros}
                    id="principalActividadOtros"
                    type="text"
                    className={classnames("font-face-msb", {
                        invalid: errorPrincipalActividadOtros
                    }, "PrincipalActividad")}
                />
                <label htmlFor="principalActividadOtros">{props.locale.dashboard_comprador_label_others}</label>
                <span className="red-text">{errorPrincipalActividadOtros}</span>
            </div>

            <div className="input-field col s12"><span className="red-text">{errorPrincipalActividad}</span></div>

            <Stats step={3} {...props} nextStep={validate} vip={props.form.vip} />
        </div>
    )
};