import React, { Component } from "react";
import { Link } from "react-router-dom";
import Localization from "../localization/localization"

class Landing extends Component {

  constructor() {
    super();
    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  render() {
    return (
      <div class="col s12 m12 l8 container">
        <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
          <div>
            <div className="font-face-mb titulo">
              <h3>
                {this.locale.layout_landing_label_welcome} <br></br> {this.locale.layout_landing_label_eventname}{" "}
                {this.locale.layout_landing_label_envenname_year}
              </h3>
            </div>
            <div className="divSpaces"></div>
            <div className="col s12 m12 l12">
              <Link
                to="/prelogin"
                style={{
                  width: "200px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem"
                }}
                className="btn-large hoverable buttonBackgroundColor"
              >
                {this.locale.dashboard_dashboard_label_message_registryconfirmation_non_buyer_0}
              </Link>
              <div className="divSpaces"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;